import React from 'react'
import NFTCard from './NFTCard'

const ApeContainer = ({dataCallback, nfts, walletA, changeCollection, collection, osLink, setExpanded,apeData,setApeData }) => {



	const apeCallback = (childData) => {

		setApeData(childData)
	}
	const expandApes = async () => {
		setExpanded(true);
	}
    var flexStyle = "flex-start";
    if (osLink.length>1) {
        flexStyle =  "center";
    } else {
        flexStyle = "flex-start"
    }

	const collections = ["TRAC's", "BAYC's","MAYC's","0xAPE'S","0xMAYC's","GACC's","DAW's","SAC's","LAYC's","PAYC's","JBAS's","ARC's","BTFD's","BADDIES's", "BTYC's","LION's","AAS's"]

	return(
           <>
           { (osLink.length<1) ?
		<div className="flexc apes">
           <div style={{justifyContent: flexStyle}} className="flexc">
			<div className="flex fcenter mgt">
           
			{ walletA ? <span className="md-txt white-txt mgr">Choose one of your {collections[collection]}</span> : <span></span>}
			{ (osLink.length<1) ? <div onClick={expandApes} className="expandApes mgl"></div> : <div className="none"></div>}
			</div>
			<div id="apeScroll" className="flex fwrap scroll">
			{nfts.map((nft, index) => {
				return <NFTCard apeCallback={apeCallback} nft={nft} key={index} />
			})}
               </div>
               </div>
           </div>
               :

               <div className="flexc apes">
                  <div style={{justifyContent: flexStyle}} className="flexc">
            <div className="flex fcenter mgt">
			<div className="flexc">
			<p className="mgl mgr sm-txt white-txt">You don't own any {collections[collection]}, get some on the secondary market</p>
            <div className="flex fwrap">
                <a href={osLink[0]} rel="noreferrer" target="_blank">
                    <div className="flexc fwrap square30 jscCenter">
                        <img className="mgb mgt" width = "40%" alt = "Shop on Opensea" src = "./opensea.png"></img>
                    </div>
                </a>
                <a href={osLink[1]} rel="noreferrer" target="_blank">
                    <div className="flexc fwrap square30 jscCenter">
                        <img className="mgb mgt" width = "40%" alt = "Shop on Looksrare" src = "./looksrare.png"></img>
                    </div>
                </a>
               </div>
            </div>
               </div>
               </div>
           </div>
               }
           </>

	
	)
}

export default ApeContainer
