import React from 'react'
import BackButton from './backButton.js'

class Mint extends React.Component {

  constructor(props) {
    super(props);
    this.state = {window: props.window, setWindow: props.setWindow};
    this.backButton = this.backButton.bind(this);
  }

  backButton() {
    this.state.setWindow("Explore")
  }
  render() {
    if (this.state.window==="Mint") {
    return(
      <>
      <p className="sdark-txt">Mint</p>
      <BackButton onClick={this.backButton} />
      </>
      );
    } else {
      return (
        <>
        </>
      );
    }
  }


}

export default Mint
